/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import Cropper from 'react-cropper';
import isEqual from 'react-fast-compare';

import * as Yup from 'yup';
import Toggle from 'react-toggle';
import VMasker from 'vanilla-masker';
import Lottie from 'lottie-react-web';
import Select, { ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FormHandles } from '@unform/core';
import { BsXCircle } from 'react-icons/bs';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { AiOutlineBulb } from 'react-icons/ai';
import { DragStart } from 'react-beautiful-dnd';
import { MaskedCurrency } from 'react-easy-mask';
import { AxiosError } from 'axios';
import * as clipboard from 'clipboard-polyfill/text';

import {
  FiPlus,
  FiInfo,
  FiList,
  FiCheck,
  FiClock,
  FiShoppingBag,
  FiTrash2,
  FiArrowRight,
  FiEye,
  FiEyeOff,
} from 'react-icons/fi';

import { ReactComponent as Person } from '../../assets/icons/person.svg';
import { ReactComponent as TwoPeople } from '../../assets/icons/2people.svg';
import { ReactComponent as FourPeople } from '../../assets/icons/4people.svg';
import { ReactComponent as ThreePeople } from '../../assets/icons/3people.svg';
import animation from '../../assets/animations/loading.json';
import thumbnail_placeholder from '../../assets/item-placeholder.png';

import IProduct from '../../models/IProduct';
import { EPlanModule } from '../../models/IPlan';
import IComplementsGroup from '../../models/IComplementsGroup';
import ISaveComplementsGroupDTO from '../../dtos/ISaveComplementsGroupDTO';

import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';
import { useProducts } from '../../hooks/products';
import { useCategories } from '../../hooks/categories';
import { useComplements } from '../../hooks/complements';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { useProductGroups } from '../../hooks/product_groups';

import Span from '../../components/Span';
import Input from '../../components/Input';
import TextArea from '../../components/Textarea';
import Availability from '../../components/Availability';
import FormlessInput from '../../components/FormlessInput';
import OptionsDialog from '../../components/OptionsDialog';
import CustomCheckbox from '../../components/CustomCheckbox';
import { NumberInputForm } from '../../components/NumberInput';
import ComplementsGroup from '../../components/ComplementsGroup';
import TagsInput from '../../components/TagsInput';
import SuggestionsModal, {
  ISuggestionProduct,
} from '../../components/SuggestionsModal';
import Disposition, { EDisposition } from '../../components/Disposition';
import SearchComplementsModal from '../../components/SearchComplementsModal';
import EditComplementsGroupModal, {
  IEditComplementsGroupRef,
} from '../../components/EditComplementsGroupModal';

import api from '../../services/api';
import { PageNames } from '../../enums/pages';
import { selectStyles } from '../../styles/select';
import { validateTime } from '../../utils/validations';
import { getValidationErrors, scrollToInputByName } from '../../utils/errors';
import { reorderArray } from '../../utils/arrays';
import { clamp, decimalPlaces, proportion } from '../../utils/numbers';
import { isSizedCategory } from '../../utils/categories';
import { chooseBsFoodOrVarejo } from '../../utils/subdomain';
import { ECategoryType } from '../../enums/categoryType';

import { IItemSize } from '../../models/IItemSizedCategory';

import {
  Top,
  Page,
  Sale,
  Main,
  Radio,
  Right,
  Title,
  Button,
  Header,
  Content,
  PageName,
  PageInfo,
  Container,
  Thumbnail,
  Pagination,
  Complements,
  Information,
  SelectLabel,
  SizeWrapper,
  ProductSizes,
  ItemSizeInput,
  PaginationName,
  SizedContainer,
  RadioContainer,
  ButtonContainer,
  QuickSaveButton,
  ScrollContainer,
  SelectContainer,
  StyledDraggable,
  StyledDroppable,
  WeightContainer,
  LoadingContainer,
  CheckboxContainer,
  MealSizeContainer,
  HorizontalWrapper,
  TextAreaContainer,
  DraggableContainer,
  RemoveSaleContainer,
  InformationContainer,
  RemoveImageContainer,
  DispositionContainer,
  AvailabilityContainer,
  StyledDragDropContext,
  NoComplementsGroupsMessage,
  Modal,
  CropImageActions,
  CropImageAction,
  CancelButton,
  HeaderButtonsContainer,
  SizeContainer,
  ToggleContainer,
  ProductInfoHeader,
  QuantityContainer,
  Label,
  ProductQuantity,
  SizeInfo,
} from './styles';

import 'cropperjs/dist/cropper.css';
import { AuthRole } from '../../hooks/auth';
import ComboItemsModal, {
  ComboItemProduct,
} from '../../components/ComboItemsModal';

interface IEditProductFormData {
  title: string;
  sellerSku: string;
  unitPrice: number;
  halfPrice: number;
  onTableUnitPrice: number;
  onTableSalePrice: number;
  salePrice: number;
  weight: number;
  prepareTime: string;
  description: string;
  productPlaceholder?: string;
}

interface ISelect {
  label: string;
  value: number;
}

interface IMeasurement {
  label: string;
  value: number;
}

interface ISuggestionDto {
  productId: number;
  suggestions: { productId: number; position: number }[];
}

type IVariation = 'PRODUCTS' | 'CATEGORIES' | 'SUBCATEGORIES';

interface IRouteParams {
  variation: IVariation;
  categoriesParams: number[];
}

enum Tabs {
  INFO = 1,
  COMPLEMENTS = 2,
  SUGGESTIONS = 3,
  AVAILABILITY = 4,
  COMBO_ITEMS = 5,
}

const EditProductPage: React.FC = () => {
  const productImageInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);

  const theme = useTheme();
  const history = useHistory();

  const { addToast } = useToast();
  const { company } = useCompany();
  const { setSelectedPage } = useSidebar();
  const { complementsGroups, loadComplementsGroups } = useComplements();

  const {
    saveProduct,
    removeImage,
    loadProducts,
    unselectProduct,
    selectedProduct,
    hasProducts,
    productMeasurements,
  } = useProducts();

  const {
    saveCategory,
    loadCategories,
    subcategories,
    categories: allCategories,
  } = useCategories();

  const { loadProductGroups, productGroups } = useProductGroups();

  const cropperRef = useRef<HTMLImageElement>(null);

  const [suggestions, setSuggestions] = useState<ISuggestionProduct[] | null>(
    [],
  );

  const [comboItems, setComboItems] = useState<ComboItemProduct[] | null>([]);

  const [loading, setLoading] = useState(false);
  const [mealSize, setMealSize] = useState('0');
  const [tab, setTab] = useState<Tabs>(1);
  const [alwaysAvailable, setAlwaysAvailable] = useState(true);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [thumbnailChanged, setThumbnailChanged] = useState(false);
  const [isIllustrativeImage, setIsIllustrativeImage] = useState(false);
  const [bsTicketPrint, setBsTicketPrint] = useState(false);
  const [onTablePriceEnabled, setOnTablePriceEnabled] = useState(false);
  const [openCropper, setOpenCropper] = useState(false);
  const [groupBeingDraggedId, setGroupBeingDraggedId] = useState('');
  const [variation, setVariation] = useState<IVariation>('PRODUCTS');
  const [notAlwaysAvailable, setNotAlwaysAvailable] = useState(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = useState(false);
  const [notRecommendedImage, setNotRecommendedImage] = useState(false);
  const [shouldAddNewCategory, setShouldAddNewCategory] = useState(false);
  const [tags, setTags] = useState<string[]>([]);

  const [comboInfo, setComboInfo] = useState({
    isCombo: false,
    disableToggle: false,
  });

  const [loadedSuggestions, setLoadedSuggestions] = useState<
    ISuggestionProduct[] | null
  >([]);

  const [itemComplementsGroups, setItemComplementsGroups] = useState<
    IComplementsGroup[]
  >([]);

  const [
    selectedComplementsGroup,
    setSelectedComplementsGroup,
  ] = useState<IComplementsGroup | null>(null);

  const [
    isEditComplementsGroupModalOpen,
    setIsEditComplementsGroupModalOpen,
  ] = useState(false);

  const [maxSalePrice, setMaxSalePrice] = useState(0);
  const [salePercentage, setSalePercentage] = useState(0);

  const [maxOnTableSalePrice, setMaxOnTableSalePrice] = useState(0);
  const [onTableSalePercentage, setOnTableSalePercentage] = useState(0);

  const [selectedCategories, setSelectedCategories] = useState<ISelect[]>([]);

  const [selectedGroup, setSelectedGroup] = useState<ISelect | null>(null);

  const [
    selectedMeasurement,
    setSelectedMeasurement,
  ] = useState<IMeasurement | null>(null);

  const [
    selectedSubcategory,
    setSelectedSubcategory,
  ] = useState<ISelect | null>(null);

  const [intervalFinalTime, setIntervalFinalTime] = useState('23:59');
  const [intervalInitialTime, setIntervalInitialTime] = useState('00:00');

  const [selectedDays, setSelectedDays] = useState<number[]>(
    Array.from({ length: 7 }, (_, k) => k),
  );

  const [dispositionTypes, setDispositionTypes] = useState<EDisposition[]>([
    'TABLE',
    'TICKET',
    'CHECKOUT',
    'DELIVERY',
    'SELFCHECKOUT',
    'DRIVE_THRU',
  ]);

  const [finalTimeError, setFinalTimeError] = useState('');
  const [initialTimeError, setInitialTimeError] = useState('');

  const [changed, setChanged] = useState(false);

  const { showConfirmDialog } = useConfirmDialog();

  const editComplementsGroupRef = useRef<IEditComplementsGroupRef>(null);

  const [categoriesCount, setCategoriesCount] = useState(
    allCategories.filter(c => c.isCategory).length,
  );

  const [subcategoriesCount, setSubcategoriesCount] = useState(
    subcategories.length,
  );

  const [shouldUpdateSubcategory, setShouldUpdateSubcategory] = useState(false);

  const [itemSizes, setItemSizes] = useState<IItemSize[]>();

  const clearInput = () => {
    const input1 = document.getElementById('thumbnail-1') as HTMLInputElement;
    const input2 = document.getElementById('thumbnail-2') as HTMLInputElement;

    if (input1) {
      input1.value = '';
    }

    if (input2) {
      input2.value = '';
    }
  };

  const mealMeasurementsType = useMemo(() => {
    const mealMeasurements = productMeasurements.map(measurement => ({
      label: `${measurement.description} (${measurement.unit})`,
      value: measurement.id,
    }));

    if (selectedProduct) {
      setSelectedMeasurement(
        mealMeasurements.find(
          m => m.value === selectedProduct?.measurement?.id,
        ) || mealMeasurements[0],
      );
    } else {
      setSelectedMeasurement(mealMeasurements[0]);
    }

    return mealMeasurements;
  }, [productMeasurements, selectedProduct]);

  useEffect(() => {
    if (history.location.state) {
      setVariation(
        (history.location.state as IRouteParams).variation || 'PRODUCTS',
      );

      const { categoriesParams } = history.location.state as IRouteParams;

      if (categoriesParams) {
        setSelectedCategories(() => {
          const selected = allCategories.filter(c =>
            categoriesParams.includes(c.id),
          );

          return selected.map(sc => {
            return {
              value: sc.id,
              label: sc.name,
            };
          });
        });
      }
    }
  }, [history, allCategories]);

  useEffect(() => {
    async function loadData() {
      await loadProducts();
      await loadCategories();
      await loadComplementsGroups();
    }

    if (company && hasProducts) {
      loadData();
    }
  }, [
    loadCategories,
    loadProducts,
    loadComplementsGroups,
    hasProducts,
    company,
  ]);

  const handleClose = useCallback(
    (bypass?: boolean) => {
      const hasChanges =
        editComplementsGroupRef.current?.complementsLength() !== 0 || changed;

      if (bypass && isEditComplementsGroupModalOpen) {
        setIsEditComplementsGroupModalOpen(false);
      } else if (bypass) {
        clearInput();
        history.goBack();
      } else if (isEditComplementsGroupModalOpen && !hasChanges) {
        setIsEditComplementsGroupModalOpen(false);
      } else if (hasChanges) {
        showConfirmDialog({
          title: 'Deseja mesmo sair?',
          onConfirm: () => {
            if (isEditComplementsGroupModalOpen) {
              setSelectedComplementsGroup(null);
              setIsEditComplementsGroupModalOpen(false);
            } else {
              clearInput();
              history.goBack();
            }
          },
          onCancel: () => {},
        });
      } else if (!changed) {
        clearInput();
        history.goBack();
      }
    },
    [history, changed, isEditComplementsGroupModalOpen, showConfirmDialog],
  );

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      } else {
        setChanged(true);
      }
    },
    [handleClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  const handleMealSizeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMealSize(event.target.value);
    },
    [],
  );

  const loadSuggestions = useMemo(
    () => async () => {
      const { data } = await api.get<IProduct[]>(
        `/restricted/products/${selectedProduct?.id}/suggestions`,
      );

      const normalizedData = Array.isArray(data)
        ? data?.map(product => ({
            id: product.id,
            title: product.title,
            imageUrl: product.imageUrl,
          }))
        : [];

      setSuggestions(normalizedData);
      setLoadedSuggestions(normalizedData);
    },
    [selectedProduct],
  );

  useEffect(() => {
    setThumbnailChanged(false);
    setTab(1);

    if (selectedProduct) {
      loadSuggestions();
      setIsIllustrativeImage(selectedProduct.isIllustrativeImage);
      setBsTicketPrint(selectedProduct.bsTicketPrintTickets);
      setOnTablePriceEnabled(selectedProduct.onTablePriceEnabled);
      setMealSize(String(selectedProduct?.size));

      if (selectedProduct?.isCombo && selectedProduct?.comboItems) {
        const normalizedComboItems = selectedProduct?.comboItems.map(
          item =>
            ({
              productId: item.productId,
              title: item.title,
              qty: item.qty,
            } as ComboItemProduct),
        );

        setComboInfo({
          isCombo: selectedProduct?.isCombo,
          disableToggle: true,
        });
        setComboItems(normalizedComboItems || []);
      }

      setItemComplementsGroups(
        selectedProduct.complementsGroups
          .filter(g => !g.deleted)
          .sort((c, n) => {
            if (c.position < n.position) {
              return -1;
            }

            if (c.position === n.position) {
              return 0;
            }

            return 1;
          }),
      );

      if (selectedProduct.intervalInitialTime) {
        setIntervalInitialTime(
          selectedProduct.intervalInitialTime.substring(0, 5),
        );
      }

      if (selectedProduct.intervalFinalTime) {
        setIntervalFinalTime(selectedProduct.intervalFinalTime.substring(0, 5));
      }

      if (selectedProduct.selectedDays.length > 0) {
        setSelectedDays(selectedProduct.selectedDays);
      }

      if (selectedProduct.alwaysAvailable) {
        setAlwaysAvailable(true);
        setNotAlwaysAvailable(false);
      } else {
        setAlwaysAvailable(false);
        setNotAlwaysAvailable(true);
      }

      if (selectedProduct.modules) {
        setDispositionTypes(
          selectedProduct.modules.split(', ') as EDisposition[],
        );
      }

      if (formRef && formRef.current) {
        formRef.current.setData(selectedProduct);
      }

      setSelectedSubcategory(
        selectedProduct.subcategory
          ? {
              value: selectedProduct.subcategory.id,
              label: selectedProduct.subcategory.name,
            }
          : null,
      );

      setSelectedCategories(
        selectedProduct.categories.map(sc => {
          return {
            value: sc.id,
            label: sc.name,
          };
        }),
      );

      if (selectedProduct?.tags?.length > 0) {
        setTags(selectedProduct.tags);
      }

      setSelectedGroup(
        selectedProduct?.productGroup
          ? {
              value: selectedProduct?.productGroup?.id,
              label: selectedProduct?.productGroup?.name,
            }
          : null,
      );

      if (
        selectedProduct?.categories?.some(category =>
          isSizedCategory(category?.type),
        )
      ) {
        loadProductGroups();
      }
    }

    return () => {
      unselectProduct();
    };
  }, [
    selectedProduct,
    history,
    unselectProduct,
    loadSuggestions,
    loadProductGroups,
  ]);

  const submitForm = useCallback((next: Tabs) => {
    setTab(next);
  }, []);

  const preview = useMemo(() => {
    return thumbnail ? URL.createObjectURL(thumbnail) : thumbnail_placeholder;
  }, [thumbnail]);

  const handleOnNavigateToSuggested = useCallback(() => {
    setTab(Tabs.SUGGESTIONS);
  }, []);

  const handleOnNavigateToAvailability = useCallback(() => {
    setTab(Tabs.AVAILABILITY);
  }, []);

  const handleOnDeleteComplementsGroup = useCallback((id: number | string) => {
    if (typeof id === 'number' && id > 0) {
      setItemComplementsGroups(prevState => {
        return prevState.map(g => {
          if (g.id === id) {
            return {
              ...g,
              deleted: true,
            };
          }

          return g;
        });
      });
    } else {
      setItemComplementsGroups(prevState => {
        return prevState.filter(group => group.id !== id);
      });
    }
  }, []);

  const handleSelectMeasurement = useCallback(
    (param: ValueType<IMeasurement>) => {
      setSelectedMeasurement(param as IMeasurement);
    },
    [],
  );

  const handleAddNewComplementsGroup = useCallback(() => {
    setIsOptionsDialogOpen(true);
  }, []);

  const handleOnDragStart = useCallback((initial: DragStart) => {
    setGroupBeingDraggedId(initial.draggableId);
  }, []);

  const getGroupId = useCallback((group: IComplementsGroup) => {
    if (group.id && group.id > 0) {
      return group.id?.toString();
    }
    if (group.localId) {
      return group.localId;
    }
    return '';
  }, []);

  const handleOnDragEnd = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (result: any) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const newComplementsGroups = reorderArray(
        itemComplementsGroups,
        result.source.index,
        result.destination.index,
      ).map((g, index) => {
        return {
          ...g,
          position: index,
        };
      });

      setItemComplementsGroups(newComplementsGroups);
    },
    [itemComplementsGroups],
  );

  const handleOnSaveComplementsGroup = useCallback(
    async (complementsGroup: ISaveComplementsGroupDTO) => {
      setSelectedComplementsGroup(null);
      setIsEditComplementsGroupModalOpen(false);

      const newComplementsGroups = [...itemComplementsGroups];

      const index = newComplementsGroups.findIndex(
        g =>
          (complementsGroup.id && g.id === complementsGroup.id) ||
          (g.localId && g.localId === complementsGroup.localId),
      );

      if (index > -1) {
        newComplementsGroups[index] = complementsGroup;
      } else {
        newComplementsGroups.push(complementsGroup);
      }

      setItemComplementsGroups(newComplementsGroups);
    },
    [itemComplementsGroups],
  );

  const saveSuggestions = useCallback(
    (id?: number) => {
      if (suggestions && !isEqual(loadedSuggestions || [], suggestions || [])) {
        return api.post<ISuggestionDto, IProduct[]>(
          '/restricted/products/suggestions',
          {
            productId: id,
            suggestions: suggestions?.map((item, index) => ({
              productId: item.id,
              position: index,
            })),
          },
        );
      }
      return null;
    },
    [loadedSuggestions, suggestions],
  );

  const updateSuggestions = useCallback(
    (id?: number) => {
      if (suggestions && !isEqual(loadedSuggestions || [], suggestions || [])) {
        return api.put<ISuggestionDto, IProduct[]>(
          '/restricted/products/suggestions',
          {
            productId: id,
            suggestions: suggestions?.map((item, index) => ({
              productId: item.id,
              position: index,
            })),
          },
        );
      }
      return null;
    },
    [loadedSuggestions, suggestions],
  );

  const handleOnLinkCopied = useCallback(async () => {
    await clipboard.writeText(
      `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
        'bsfood',
        'bsvarejo',
      )}.com.br/product?hash=${selectedProduct?.hash}`,
    );

    addToast({
      type: 'info',
      description: 'Copiado para àrea de transferência.',
    });
  }, [company, addToast, selectedProduct]);

  const handleOnOptionsDialogClosed = useCallback(() => {
    setIsOptionsDialogOpen(false);
  }, []);

  const handleOnOptionsDialogConfirmed = useCallback((option: string) => {
    setIsOptionsDialogOpen(false);

    if (option === 'NEW') {
      setIsEditComplementsGroupModalOpen(true);
    } else {
      setIsSearchDialogOpen(true);
    }
  }, []);

  const handleOnSearchModalClosed = useCallback(() => {
    setIsSearchDialogOpen(false);
  }, []);

  const handleOnSearchModalConfirmed = useCallback(
    (group: IComplementsGroup) => {
      setItemComplementsGroups(oldState => [group, ...oldState]);
    },
    [],
  );

  const formattedCategories = useMemo(() => {
    return allCategories
      .filter(c => c.isCategory && c.active)
      .map(c => {
        return {
          value: c.id,
          label: c.name,
        };
      });
  }, [allCategories]);

  const formattedSubcategories = useMemo(() => {
    return subcategories
      .filter(c => c.isSubcategory && c.active)
      .map(subcategory => {
        return {
          value: subcategory.id,
          label: subcategory.name,
        };
      });
  }, [subcategories]);

  const formattedGroups = useMemo(() => {
    return (
      productGroups
        // .filter(group => group.active)
        ?.map(group => {
          return {
            value: group.id,
            label: group.name,
          };
        })
    );
  }, [productGroups]);

  const productLink = useMemo(
    () =>
      `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
        'bsfood',
        'bsvarejo',
      )}.com.br/product?hash=${selectedProduct?.hash}`,
    [selectedProduct, company],
  );

  const dialogOptions = useMemo(() => {
    return [
      {
        ref: 'NEW',
        title: 'Específico',
      },
      {
        ref: 'EXISTING',
        title: 'Buscar',
      },
    ];
  }, []);

  const groupsNotAdded = useMemo(() => {
    return complementsGroups.filter(
      cg =>
        !itemComplementsGroups
          .filter(group => !group.deleted)
          .map(group => group.id)
          .includes(cg.id),
    );
  }, [complementsGroups, itemComplementsGroups]);

  const handleOnAlwaysAvailableClicked = useCallback(() => {
    setAlwaysAvailable(true);
    setNotAlwaysAvailable(false);
  }, []);

  const handleOnNotAlwaysAvailableClicked = useCallback(() => {
    setNotAlwaysAvailable(true);
    setAlwaysAvailable(false);
  }, []);

  const handleOnDayClicked = useCallback((day: number) => {
    setSelectedDays(prevState => {
      if (prevState.length === 1 && prevState.includes(day)) {
        return prevState;
      }

      const index = prevState.findIndex(selectedDay => selectedDay === day);

      if (index >= 0) {
        return prevState.filter(selectedDay => selectedDay !== day);
      }

      return [...prevState, day];
    });
  }, []);

  const handleOnDispositionClicked = useCallback(
    (disposition: EDisposition) => {
      setDispositionTypes(prevState => {
        if (prevState.includes(disposition)) {
          if (prevState.length === 1) {
            return prevState;
          }

          return prevState.filter(d => d !== disposition);
        }

        return [...prevState, disposition];
      });
    },
    [],
  );

  const handleOnIntervalChanged = useCallback(
    (i: 'INITIAL' | 'FINAL', value: string) => {
      if (i === 'INITIAL') {
        setInitialTimeError('');
        setIntervalInitialTime(VMasker.toPattern(value, '99:99'));
      } else {
        setFinalTimeError('');
        setIntervalFinalTime(VMasker.toPattern(value, '99:99'));
      }
    },
    [],
  );

  const handleSuggestionChange = useCallback(
    (newSuggestions?: ISuggestionProduct[] | null) => {
      setSuggestions(newSuggestions || null);
    },
    [],
  );

  const handleComboItemsChange = useCallback(
    (newComboItems?: ComboItemProduct[] | null) => {
      setComboItems(newComboItems || null);
    },
    [],
  );

  const handleOnNewCategoryCreated = useCallback(
    async (category: string) => {
      try {
        await saveCategory(
          {
            name: category,
            isActive: true,
            isCategory: true,
            isSubcategory: false,
            pizzaSizes: [],
            type: ECategoryType.STANDARD,
          },
          0,
        );

        setShouldAddNewCategory(true);
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao salvar categoria',
        });
      }
    },
    [addToast, saveCategory],
  );

  const handleOnNewSubcategoryCreated = useCallback(
    async (category: string) => {
      try {
        await saveCategory(
          {
            name: category,
            isActive: true,
            isCategory: false,
            isSubcategory: true,
            pizzaSizes: [],
            type: ECategoryType.STANDARD,
          },
          0,
        );

        setShouldUpdateSubcategory(true);
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao salvar subcategoria',
        });
      }
    },
    [addToast, saveCategory],
  );

  // const handleOnNewGroupCreated = useCallback(() => {
  //   console.log('Group created');
  // }, []);

  const handleChangeSalePrice = useCallback(
    (value: number, isTable?: boolean) => {
      let result = 0;
      if (value > 0) {
        const unitPrice = formRef.current?.getFieldValue(
          isTable ? 'onTableUnitPrice' : 'unitPrice',
        ) as number;
        const newPercentage = proportion(unitPrice, 100, value);
        result = decimalPlaces(100 - newPercentage, 2);
      }

      if (isTable) {
        return setOnTableSalePercentage(result);
      }

      return setSalePercentage(result);
    },
    [],
  );

  const handleSalePercentageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, isTable?: boolean) => {
      const value = clamp(Number(event.target.value), 0, 99);

      if (value > 0) {
        const unitPrice = formRef.current?.getFieldValue(
          isTable ? 'onTableUnitPrice' : 'unitPrice',
        ) as number;
        const newSalePrice = unitPrice - proportion(100, unitPrice, value);

        formRef.current?.setFieldValue(
          isTable ? 'onTableSalePrice' : 'salePrice',
          decimalPlaces(newSalePrice, 2),
        );

        const percentage = decimalPlaces(value, 2);

        if (isTable) {
          setOnTableSalePercentage(percentage);
        } else {
          setSalePercentage(percentage);
        }
      } else {
        formRef.current?.setFieldValue(
          isTable ? 'onTableSalePrice' : 'salePrice',
          0,
        );

        if (isTable) {
          setOnTableSalePercentage(0);
        } else {
          setSalePercentage(0);
        }
      }
    },
    [],
  );

  const handleRemoveSale = useCallback(isTable => {
    formRef.current?.setFieldValue(
      isTable ? 'onTableSalePrice' : 'salePrice',
      0,
    );

    if (isTable) {
      setOnTableSalePercentage(0);
    } else {
      setSalePercentage(0);
    }
  }, []);

  useEffect(() => {
    if (categoriesCount < formattedCategories.length && shouldAddNewCategory) {
      setSelectedCategories(prevState => [
        ...prevState,
        formattedCategories[0],
      ]);

      setCategoriesCount(formattedCategories.length);
      setShouldAddNewCategory(false);
    }
  }, [categoriesCount, formattedCategories, shouldAddNewCategory]);

  useEffect(() => {
    if (
      formattedSubcategories &&
      shouldUpdateSubcategory &&
      subcategoriesCount < formattedSubcategories.length
    ) {
      setSelectedSubcategory(formattedSubcategories[0]);

      setShouldUpdateSubcategory(false);
      setCategoriesCount(formattedCategories.length);
      setSubcategoriesCount(formattedSubcategories.length);
    }
  }, [
    subcategoriesCount,
    formattedCategories,
    formattedSubcategories,
    shouldUpdateSubcategory,
  ]);

  useEffect(() => {
    if (selectedProduct) {
      setMaxSalePrice(decimalPlaces(selectedProduct.unitPrice * 0.99, 2));
      setMaxOnTableSalePrice(
        decimalPlaces(selectedProduct.onTableUnitPrice * 0.99, 2),
      );

      if (selectedProduct.salePrice) {
        setSalePercentage(
          decimalPlaces(
            100 -
              proportion(
                selectedProduct.unitPrice,
                100,
                selectedProduct.salePrice,
              ),
            2,
          ),
        );
      }

      if (selectedProduct.onTableSalePrice) {
        setOnTableSalePercentage(
          decimalPlaces(
            100 -
              proportion(
                selectedProduct.onTableUnitPrice,
                100,
                selectedProduct.onTableSalePrice,
              ),
            2,
          ),
        );
      }
    } else {
      setSalePercentage(0);
      setOnTableSalePercentage(0);
    }
  }, [selectedProduct]);

  const handleOnThumbnailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target?.files ? event.target?.files[0] : null;

      if (!file) {
        return;
      }

      setNotRecommendedImage(false);

      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        // eslint-disable-next-line no-alert
        alert('Tipo de arquivo inválido!');
        return;
      }

      const img = new Image();

      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width < 600 || img.height < 600) {
          setNotRecommendedImage(true);
        }
      };

      if (file && file.size > 4194304) {
        // eslint-disable-next-line no-alert
        alert('A imagem deve ser de até 4mb!');
        return;
      }

      if (file) {
        setThumbnail(file);
        setThumbnailChanged(true);
        setOpenCropper(true);

        if (event && event.target) {
          // eslint-disable-next-line no-param-reassign
          event.target.value = '';
        }
      }
    },
    [],
  );

  const handleProductImageClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      productImageInputRef.current?.click();
    },
    [],
  );

  const handleOnRemoveImage = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        await removeImage(selectedProduct?.id || 0);
        setThumbnail(null);

        addToast({
          type: 'success',
          description: 'Imagem removida!',
        });

        clearInput();
      } catch {
        addToast({
          type: 'error',
          description: 'Verifique a sua conexão e tente novamente',
        });
      }
    },
    [removeImage, addToast, selectedProduct],
  );

  const handleChangeUnitPrice = useCallback(
    (value: number, isTable?: boolean) => {
      const max = decimalPlaces(value * 0.99, 2);

      handleRemoveSale(isTable);

      if (isTable) {
        setMaxOnTableSalePrice(max);
      } else {
        setMaxSalePrice(max);
      }
    },
    [handleRemoveSale],
  );

  // const hasSizedCategories = useMemo(() => {
  //   // const selectedCategoriesIds = selectedCategories.map(sc => sc.value);

  //   // const selectedCategoriesObjects = allCategories.filter(c =>
  //   //   selectedCategoriesIds.includes(c.id),
  //   // );

  //   // const sizedCategories = selectedCategoriesObjects.filter(co =>
  //   //   isSizedCategory(co.type),
  //   // );

  //   // if (sizedCategories) {
  //   //   return sizedCategories.length > 0;
  //   // }

  //   return false;
  // }, [selectedCategories, allCategories, isSizedCategory]);

  const selectedCategoryObject = useMemo(() => {
    if (selectedCategories && selectedCategories[0]) {
      return allCategories.find(c => c.id === selectedCategories[0].value);
    }

    return undefined;
  }, [allCategories, selectedCategories]);

  const handleOnChangeSizePrice = useCallback((value: number, id: number) => {
    setItemSizes(prevState => {
      if (prevState) {
        const next = [...prevState];
        const index = prevState?.findIndex(s => s.pizzaSizeId === id);

        if (index > -1) {
          next[index].price = value;
          return next;
        }
      }

      return prevState;
    });
  }, []);

  const handleOnChangeSizeIntegration = useCallback(
    (value: string, id: number) => {
      setItemSizes(prevState => {
        if (prevState) {
          const next = [...prevState];
          const index = prevState?.findIndex(s => s.pizzaSizeId === id);

          if (index > -1) {
            next[index].integrationCode = value;
            return next;
          }
        }

        return prevState;
      });
    },
    [],
  );

  useEffect(() => {
    if (
      selectedCategoryObject &&
      isSizedCategory(selectedCategoryObject.type)
    ) {
      let filteredSizes: IItemSize[] = [];

      if (selectedProduct && selectedProduct?.pizzaSizes.length > 0) {
        filteredSizes = selectedProduct?.pizzaSizes?.filter(pSz =>
          selectedCategoryObject?.pizzaSizes?.some(
            cPz => cPz?.id === pSz.pizzaSizeId,
          ),
        );
      }

      if (filteredSizes?.length > 0) {
        setItemSizes(
          filteredSizes.map((s, index) => {
            const size = selectedCategoryObject?.pizzaSizes.find(
              ps => ps.id === s.pizzaSizeId,
            );

            return {
              active: s?.active,
              error: false,
              position: index,
              price: s.price || 0,
              name: size?.name || '',
              slices: size?.slices || 1,
              pizzaSizeId: s.pizzaSizeId,
              flavours: size?.flavours || 1,
              integrationCode: s.integrationCode || '',
            };
          }),
        );
      } else {
        setItemSizes(
          selectedCategoryObject.pizzaSizes.map((s, index) => {
            return {
              price: 0,
              active: true,
              error: false,
              name: s.name,
              position: index,
              slices: s.slices,
              integrationCode: '',
              flavours: s.flavours,
              pizzaSizeId: s.id || 0,
            };
          }),
        );
      }
    } else {
      setItemSizes([]);
    }
  }, [selectedCategoryObject, selectedProduct]);

  const handleOnCategoryChanged = useCallback(
    async (param: ValueType<ISelect>) => {
      if (param) {
        const ids = (param as ISelect[]).map(p => p.value);

        const categoriesToBeAdded = allCategories.filter(c =>
          ids.includes(c.id),
        );

        const hasSizedCategories = categoriesToBeAdded.some(c =>
          isSizedCategory(c.type),
        );

        if (categoriesToBeAdded.length > 1 && hasSizedCategories) {
          addToast({
            type: 'error',
            description:
              'Categorias com tamanhos devem ser únicas dentro de um produto.',
          });

          return;
        }

        if (hasSizedCategories) {
          await loadProductGroups();
        }
      }

      setSelectedCategories(param as ISelect[]);
    },
    [allCategories, addToast, loadProductGroups],
  );

  const handleOnSubcategoryChanged = useCallback(
    (param: ValueType<ISelect>) => {
      setSelectedSubcategory(param as ISelect);
    },
    [],
  );

  const handleOnGroupChanged = useCallback((param: ValueType<ISelect>) => {
    setSelectedGroup(param as ISelect);
  }, []);

  const handleOnNavigateToComplements = useCallback(
    async (isCombo?: boolean) => {
      const data = formRef.current?.getData() as IEditProductFormData;
      formRef.current?.setErrors({});

      if (isCombo && comboItems?.length === 0) {
        addToast({
          type: 'info',
          description: 'É necessário adicionar pelo menos um item ao combo.',
        });
        setTab(Tabs.COMBO_ITEMS);
        return true;
      }

      if (
        isSizedCategory(selectedCategoryObject?.type || ECategoryType.STANDARD)
      ) {
        if (itemSizes) {
          const errored = itemSizes.filter(s => {
            if (!s.price) {
              if (!s.active) {
                return false;
              }

              return true;
            }

            return false;
          });

          if (errored && errored.length > 0) {
            const indexes = errored.map(e => {
              return itemSizes?.findIndex(s => s.pizzaSizeId === e.pizzaSizeId);
            });

            setItemSizes(prevState => {
              if (prevState) {
                const next = [...prevState];

                indexes.forEach(i => {
                  next[i].error = true;
                });

                return next;
              }

              return prevState;
            });

            const element = document.getElementById('product_sizes');

            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }

            return true;
          }

          if (itemSizes.every(s => !s.active)) {
            addToast({
              type: 'error',
              description: 'Ao menos um tamanho deve estar ativado.',
            });

            return true;
          }
        }
      }

      if (
        !selectedCategories ||
        (selectedCategories && selectedCategories.length === 0)
      ) {
        addToast({
          type: 'error',
          description: 'Selecione uma categoria.',
        });
        setTab(1);
        return true;
      }

      if (
        onTablePriceEnabled &&
        data.onTableUnitPrice <= 0 &&
        data.onTableSalePrice <= 0
      ) {
        addToast({
          type: 'error',
          description: 'O valor na mesa deve ser maior que 0 (zero).',
        });

        return true;
      }

      try {
        const schema = Yup.object().shape({
          title: Yup.string()
            .min(3, 'Mínimo de 3 caracteres.')
            .required('O nome é obrigatório.'),
          description: Yup.string().test(
            'description-length',
            'Se presente, mínimo de 3 caracteres.',
            async (value: string | null | undefined) => {
              if (value && value.length < 3) {
                return false;
              }
              return true;
            },
          ),
          weight: Yup.number().typeError('Este campo é obrigatório.'),
          prepareTime: Yup.string().test(
            'prepareTime-length',
            'Se presente, mínimo de 3 caracteres.',
            async (value: string | null | undefined) => {
              if (value && value.length < 3) {
                return false;
              }
              return true;
            },
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setTab(
          isSizedCategory(
            selectedCategoryObject?.type || ECategoryType.STANDARD,
          )
            ? Tabs.SUGGESTIONS
            : isCombo
            ? Tabs.COMBO_ITEMS
            : Tabs.COMPLEMENTS,
        );
        return false;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const formErrors = getValidationErrors(err);

          formRef.current?.setErrors(formErrors);

          scrollToInputByName(Object.keys(formErrors)[0]);
        }
        setTab(1);
        return true;
      }
    },
    [
      addToast,
      comboItems,
      itemSizes,
      onTablePriceEnabled,
      selectedCategories,
      selectedCategoryObject,
    ],
  );

  const handleSaveProduct = useCallback(async () => {
    const hasErrors = await handleOnNavigateToComplements(comboInfo?.isCombo);
    if (loading || hasErrors) return;

    const formData = formRef.current?.getData() as IEditProductFormData;

    const isFinalTimeErrored = !validateTime(intervalFinalTime);
    const isInitialTimeErrored = !validateTime(intervalInitialTime);

    if (notAlwaysAvailable && (isFinalTimeErrored || isInitialTimeErrored)) {
      setFinalTimeError(isFinalTimeErrored ? 'Hora inválida!' : '');
      setInitialTimeError(isInitialTimeErrored ? 'Hora inválida!' : '');
      setTab(4);
      return;
    }

    setLoading(true);

    try {
      const isEdit = !!selectedProduct;

      const data = new FormData();
      data.append('active', 'true');
      data.append('companyId', String(company?.id));

      data.append(
        'unitPrice',
        formData?.unitPrice
          ? String(formData.unitPrice).replace('.', ',')
          : '0',
      );
      data.append(
        'halfPrice',
        formData?.unitPrice
          ? String(formData.halfPrice).replace('.', ',')
          : '0',
      );
      data.append(
        'salePrice',
        formData?.salePrice
          ? String(formData.salePrice).replace('.', ',')
          : '0',
      );

      data.append('onTablePriceEnabled', onTablePriceEnabled?.toString());
      data.append(
        'onTableUnitPrice',
        formData?.onTableUnitPrice
          ? String(formData.onTableUnitPrice).replace('.', ',')
          : '0',
      );
      data.append(
        'onTableSalePrice',
        formData?.onTableSalePrice
          ? String(formData.onTableSalePrice).replace('.', ',')
          : '0',
      );

      data.append('size', String(mealSize));
      data.append('measurementId', String(selectedMeasurement?.value));

      selectedCategories
        .filter(c => c.value > 0)
        .forEach(sc => {
          data.append('categories[]', String(sc.value));
        });

      data.append('subcategoryId', String(selectedSubcategory?.value || ''));

      if (notAlwaysAvailable) {
        data.append('alwaysAvailable', 'false');
        data.append('intervalFinalTime', `${intervalFinalTime}:00`);
        data.append('intervalInitialTime', `${intervalInitialTime}:00`);

        selectedDays.forEach(day => {
          data.append('selectedDays[]', String(day));
        });
      } else {
        data.append('alwaysAvailable', 'true');
      }

      if (selectedProduct) {
        data.append('id', String(selectedProduct.id));
      }

      if (formData || selectedProduct) {
        data.append(
          'title',
          formData?.title !== selectedProduct?.title
            ? formData?.title
            : selectedProduct?.title,
        );

        data.append(
          'description',
          formData?.description !== selectedProduct?.description
            ? formData?.description
            : selectedProduct?.description,
        );

        data.append(
          'weight',
          formData?.weight
            ? String(formData?.weight).replace('.', ',')
            : String(selectedProduct?.weight),
        );

        data.append(
          'prepareTime',
          formData?.prepareTime !== selectedProduct?.prepareTime
            ? formData?.prepareTime
            : selectedProduct?.prepareTime,
        );

        data.append(
          'sellerSku',
          formData?.sellerSku !== selectedProduct?.sellerSku
            ? formData?.sellerSku
            : selectedProduct?.sellerSku,
        );
      }

      if (thumbnail && typeof thumbnail !== 'string') {
        data.append('image', thumbnail);
      }

      data.append('isIllustrativeImage', isIllustrativeImage?.toString());
      data.append('bsTicketPrintTickets', bsTicketPrint?.toString());
      data.append(
        'complementsGroups[]',
        JSON.stringify(
          itemComplementsGroups.map((item, index) => ({
            id: item.id,
            position: index,
            deleted: item.deleted,
          })),
        ),
      );

      if (
        selectedCategoryObject &&
        isSizedCategory(selectedCategoryObject.type) &&
        itemSizes
      ) {
        console.log(itemSizes);
        data.append('pizzaSizes[]', JSON.stringify(itemSizes));

        data.append(
          'productGroupId',
          selectedGroup ? selectedGroup?.value?.toString() : '',
        );
      }

      data.append('modules', dispositionTypes.join(','));

      data.append('productPlaceholder', formData?.productPlaceholder || '');

      tags.forEach(tag => {
        data.append('tags[]', tag);
      });

      if (comboInfo?.isCombo) {
        data.append('isCombo', 'true');
        data.append('comboItems[]', JSON.stringify(comboItems));
      }

      const createdProduct = await saveProduct(data, selectedProduct?.id || 0);

      if (selectedProduct?.id) {
        await updateSuggestions(selectedProduct.id);
      } else {
        await saveSuggestions(createdProduct?.id);
      }

      addToast({
        type: 'success',
        description: `Você ${
          isEdit ? 'alterou' : 'cadastrou'
        } o produto com sucesso.`,
      });

      setLoading(false);
      handleClose(true);
    } catch (err) {
      clearInput();
      const errors = (err as AxiosError)?.response?.data?.errors?.messages;
      console.log(err);
      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });

      setLoading(false);
    }
  }, [
    handleOnNavigateToComplements,
    loading,
    intervalFinalTime,
    intervalInitialTime,
    notAlwaysAvailable,
    selectedProduct,
    company,
    onTablePriceEnabled,
    mealSize,
    selectedMeasurement,
    selectedCategories,
    selectedSubcategory,
    thumbnail,
    isIllustrativeImage,
    bsTicketPrint,
    itemComplementsGroups,
    selectedCategoryObject,
    itemSizes,
    dispositionTypes,
    tags,
    comboInfo,
    saveProduct,
    addToast,
    handleClose,
    selectedDays,
    selectedGroup,
    comboItems,
    updateSuggestions,
    saveSuggestions,
  ]);

  const onCancelImageInput = () => {
    setThumbnail(null);

    setNotRecommendedImage(false);

    setThumbnailChanged(false);

    setOpenCropper(false);
  };

  const onCropImage = async () => {
    const cropper = (cropperRef?.current as any)?.cropper as any;

    const canvas = cropper.getCroppedCanvas();
    canvas.toBlob((blob: any) => {
      const file = new File([blob], thumbnail?.name || '', {
        type: thumbnail?.type,
      });
      setOpenCropper(false);
      setThumbnail(file);
    }, thumbnail?.type);
  };

  const toggleProductSize = (sizeId: number) => {
    setItemSizes(old =>
      old?.map(size =>
        size?.pizzaSizeId === sizeId
          ? { ...size, active: !size?.active }
          : size,
      ),
    );
  };

  const showsPriceInput = useMemo(() => {
    if (!selectedCategoryObject) {
      return true;
    }

    if (isSizedCategory(selectedCategoryObject.type)) {
      return false;
    }

    return true;
  }, [selectedCategoryObject]);

  const handleOnTagsChange = (newTags: typeof tags) => {
    setTags(newTags);
  };

  const handleOnIsIllustrativeImageChange = () => {
    setIsIllustrativeImage(old => !old);
  };

  const handleOnBsTicketPrintChange = () => {
    setBsTicketPrint(old => !old);
  };

  const handleOnIsComboChange = () => {
    setComboInfo(old => ({ ...old, isCombo: !old.isCombo }));
  };

  const handleOnTablePriceEnabledChange = () => {
    setOnTablePriceEnabled(old => {
      if (old) {
        handleRemoveSale(true);
      }
      return !old;
    });
  };

  const pageName = `${selectedProduct ? 'Editar' : 'Criar'} ${
    comboInfo.isCombo ? 'combo' : 'produto'
  }`;

  useEffect(() => {
    setSelectedPage(PageNames.EDIT_PRODUCT);
  }, [setSelectedPage]);

  // const isOnlyBSTicketModule = useMemo(
  //   () => (company?.subscription?.plan?.modules || '') !== EPlanModule.BSTICKET,
  //   [company],
  // );

  return (
    <Container>
      <Content>
        <Header>
          <PageInfo>
            <PageName>{pageName}</PageName>
          </PageInfo>
          <HeaderButtonsContainer>
            <CancelButton type="button" onClick={() => handleClose(true)}>
              Cancelar
            </CancelButton>

            {selectedProduct?.id && (
              <AuthRole blackList={['Employee']}>
                <QuickSaveButton onClick={handleSaveProduct}>
                  Salvar
                </QuickSaveButton>
              </AuthRole>
            )}
          </HeaderButtonsContainer>
        </Header>
        <Main>
          <Pagination>
            <Page
              selected={tab === Tabs.INFO}
              onClick={() => submitForm(Tabs.INFO)}
            >
              <FiInfo size={24} />
              <PaginationName>Informações</PaginationName>
            </Page>

            {comboInfo.isCombo && (
              <Page
                selected={tab === Tabs.COMBO_ITEMS}
                onClick={() => submitForm(Tabs.COMBO_ITEMS)}
              >
                <FiShoppingBag size={24} />
                <PaginationName>Itens Do Combo</PaginationName>
              </Page>
            )}

            <Page
              selected={tab === Tabs.COMPLEMENTS}
              onClick={() => submitForm(Tabs.COMPLEMENTS)}
            >
              <FiList size={24} />
              <PaginationName>Complementos</PaginationName>
            </Page>
            <AuthRole blackList={['Employee']}>
              <Page
                selected={tab === Tabs.SUGGESTIONS}
                onClick={() => submitForm(Tabs.SUGGESTIONS)}
              >
                <AiOutlineBulb size={24} />
                <PaginationName>Sugestões</PaginationName>
              </Page>
            </AuthRole>
            <Page
              selected={tab === Tabs.AVAILABILITY}
              onClick={() => submitForm(Tabs.AVAILABILITY)}
            >
              <FiClock size={24} />
              <PaginationName>Disponibilidade</PaginationName>
            </Page>
          </Pagination>
          <ScrollContainer
            className="has-custom-scroll-bar-2"
            visible={tab === Tabs.INFO}
          >
            <Information
              ref={formRef}
              id="information"
              initialData={selectedProduct || {}}
              className="has-custom-scroll-bar-2"
              onSubmit={() => handleOnNavigateToComplements(comboInfo.isCombo)}
            >
              <ProductInfoHeader>
                <ToggleContainer>
                  <Toggle
                    icons={false}
                    id="is-combo"
                    checked={comboInfo.isCombo}
                    onChange={handleOnIsComboChange}
                    disabled={!!selectedProduct}
                  />
                  <span style={{ fontSize: 16 }}>É um combo?</span>
                </ToggleContainer>
                {selectedProduct &&
                  (company?.subscription?.plan.modules?.includes(
                    EPlanModule.BSSELFCHECKOUT,
                  ) ||
                    (company?.subscription?.plan.modules?.includes(
                      EPlanModule.BSTICKET,
                    ) &&
                      company?.bsTicketEnablesStockControl && (
                        <QuantityContainer>
                          <Label>Qtde.</Label>
                          <ProductQuantity>
                            {selectedProduct.availableStock}
                          </ProductQuantity>
                        </QuantityContainer>
                      )))}
              </ProductInfoHeader>

              <Top>
                <Modal
                  isOpen={openCropper}
                  shouldCloseOnEsc
                  shouldCloseOnOverlayClick
                  style={{
                    overlay: {
                      background: 'rgba(0, 0, 0, .8)',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Cropper
                    src={preview}
                    style={{ height: '50vh', width: '100%' }}
                    aspectRatio={1 / 1}
                    ref={cropperRef}
                  />
                  <CropImageActions>
                    <ToggleContainer>
                      <Toggle
                        icons={false}
                        id="is-illustrative-image"
                        checked={isIllustrativeImage}
                        onChange={handleOnIsIllustrativeImageChange}
                      />
                      <span>Imagem meramente ilustrativa</span>
                    </ToggleContainer>
                    <div className="cropImageButtonsContainer">
                      <CancelButton type="button" onClick={onCancelImageInput}>
                        Cancelar
                      </CancelButton>
                      <CropImageAction onClick={onCropImage}>
                        Salvar
                        <FiCheck size={24} />
                      </CropImageAction>
                    </div>
                  </CropImageActions>
                </Modal>
                <AuthRole blackList={['Employee']} disableOnly>
                  <Thumbnail
                    style={{
                      backgroundImage: `url(${
                        selectedProduct?.imageUrl && !thumbnailChanged
                          ? selectedProduct.imageUrl
                          : preview
                      })`,
                    }}
                  >
                    {selectedProduct?.imageUrl && !thumbnailChanged && (
                      <AuthRole blackList={['Employee']}>
                        <RemoveImageContainer
                          onClick={handleOnRemoveImage}
                          type="button"
                        >
                          <FiTrash2 size={18} />
                        </RemoveImageContainer>
                      </AuthRole>
                    )}
                    <input
                      ref={productImageInputRef}
                      type="file"
                      onChange={handleOnThumbnailChange}
                      accept="image/x-png,image/jpeg"
                    />
                    <button
                      type="button"
                      className="add-image"
                      onClick={handleProductImageClick}
                    >
                      Alterar imagem
                    </button>
                  </Thumbnail>
                </AuthRole>

                <Right>
                  <HorizontalWrapper>
                    <AuthRole blackList={['Employee']} disableOnly>
                      <Input
                        id="title"
                        name="title"
                        title="Nome"
                        style={{ scrollMarginTop: '40px' }}
                      />
                    </AuthRole>
                    <AuthRole blackList={['Employee']} disableOnly>
                      {showsPriceInput && (
                        <>
                          <NumberInputForm
                            title={
                              onTablePriceEnabled
                                ? 'Preço para delivery'
                                : 'Preço'
                            }
                            name="unitPrice"
                            onChange={handleChangeUnitPrice}
                          />
                          <NumberInputForm
                            title="Meio preço"
                            name="halfPrice"
                          />
                          {onTablePriceEnabled && (
                            <NumberInputForm
                              title="Preço para mesa"
                              name="onTableUnitPrice"
                              onChange={value =>
                                handleChangeUnitPrice(value, true)
                              }
                            />
                          )}
                        </>
                      )}
                    </AuthRole>
                  </HorizontalWrapper>
                  <HorizontalWrapper>
                    <ToggleContainer style={{ marginTop: 16 }}>
                      <Toggle
                        icons={false}
                        id="on-table-price-enabled"
                        checked={onTablePriceEnabled}
                        onChange={handleOnTablePriceEnabledChange}
                      />
                      <span>Habilitar tabela de preços (delivery e mesa)</span>
                    </ToggleContainer>

                    {company?.subscription?.plan?.modules?.includes(
                      EPlanModule.BSTICKET,
                    ) && (
                      <ToggleContainer style={{ marginTop: 16 }}>
                        <Toggle
                          icons={false}
                          id="enable-bsticket-print"
                          checked={bsTicketPrint}
                          onChange={handleOnBsTicketPrintChange}
                        />
                        <span>Habilitar impressão de tickets (BS Ticket)</span>
                      </ToggleContainer>
                    )}
                  </HorizontalWrapper>
                  {variation === 'PRODUCTS' ? (
                    <HorizontalWrapper className="margin-top">
                      <SelectContainer>
                        <SelectLabel>Categoria</SelectLabel>
                        <AuthRole blackList={['Employee']} disableOnly>
                          <CreatableSelect
                            isMulti
                            isClearable
                            styles={selectStyles}
                            value={selectedCategories}
                            options={formattedCategories}
                            onChange={handleOnCategoryChanged}
                            placeholder="Selecione a categoria"
                            onCreateOption={handleOnNewCategoryCreated}
                          />
                        </AuthRole>
                      </SelectContainer>
                      {(!selectedCategories
                        ? true
                        : selectedCategories?.every(
                            c =>
                              (allCategories?.find(
                                current => current.id === c.value,
                              )?.pizzaSizes?.length || 0) <= 0,
                          )) && (
                        <SelectContainer>
                          <SelectLabel>Subcategoria</SelectLabel>
                          <AuthRole blackList={['Employee']} disableOnly>
                            <CreatableSelect
                              isClearable
                              styles={selectStyles}
                              value={selectedSubcategory}
                              options={formattedSubcategories}
                              onChange={handleOnSubcategoryChanged}
                              placeholder="Selecione a subcategoria"
                              onCreateOption={handleOnNewSubcategoryCreated}
                            />
                          </AuthRole>
                        </SelectContainer>
                      )}
                      {selectedCategories?.some(
                        category =>
                          allCategories?.find(
                            current => current?.id === category?.value,
                          )?.type === ECategoryType.PIZZA,
                      ) && (
                        <HorizontalWrapper>
                          <SelectContainer>
                            <SelectLabel>Grupo de pizza</SelectLabel>
                            <AuthRole blackList={['Employee']} disableOnly>
                              <Select
                                isClearable
                                styles={selectStyles}
                                value={selectedGroup}
                                options={formattedGroups}
                                onChange={handleOnGroupChanged}
                                placeholder="Selecione o grupo"
                                // onCreateOption={handleOnNewGroupCreated}
                              />
                            </AuthRole>
                          </SelectContainer>
                        </HorizontalWrapper>
                      )}
                    </HorizontalWrapper>
                  ) : (
                    <HorizontalWrapper className="margin-top">
                      <SelectContainer>
                        <SelectLabel>Subcategoria</SelectLabel>
                        <AuthRole blackList={['Employee']} disableOnly>
                          <CreatableSelect
                            isClearable
                            styles={selectStyles}
                            value={selectedSubcategory}
                            options={formattedSubcategories}
                            onChange={handleOnSubcategoryChanged}
                            placeholder="Selecione a subcategoria"
                            onCreateOption={handleOnNewSubcategoryCreated}
                          />
                        </AuthRole>
                      </SelectContainer>
                    </HorizontalWrapper>
                  )}
                </Right>
              </Top>
              {notRecommendedImage && (
                <InformationContainer>
                  <FiInfo size={24} />
                  <span>
                    Para melhor visualização recomenda-se o tamanho de 600x600
                    para imagens.
                  </span>
                </InformationContainer>
              )}
              <AuthRole blackList={['Employee']} disableOnly>
                {(thumbnail || selectedProduct?.imageUrl) && (
                  <ToggleContainer className="margin">
                    <Toggle
                      icons={false}
                      id="is-illustrative-image"
                      checked={isIllustrativeImage}
                      onChange={handleOnIsIllustrativeImageChange}
                    />
                    <span>Imagem meramente ilustrativa</span>
                  </ToggleContainer>
                )}
                <TagsInput
                  title="Tags"
                  value={tags}
                  onChange={handleOnTagsChange}
                  name="tags"
                  placeholder="Digite as tags"
                />
              </AuthRole>
              {!isSizedCategory(
                selectedCategoryObject?.type || ECategoryType.STANDARD,
              ) &&
                [false, ...(onTablePriceEnabled ? [true] : [])].map(isTable => (
                  <Sale key={isTable ? 'table' : 'delivery'}>
                    <AuthRole blackList={['Employee']}>
                      <RemoveSaleContainer
                        type="button"
                        onClick={() => handleRemoveSale(isTable)}
                      >
                        <FiTrash2 size={18} />
                      </RemoveSaleContainer>
                    </AuthRole>
                    <legend>
                      {`Promoção ${
                        isTable
                          ? 'na mesa / BS Ticket'
                          : onTablePriceEnabled
                          ? 'no delivery'
                          : ''
                      }`}
                    </legend>
                    <div className="wrapper">
                      <AuthRole blackList={['Employee']} disableOnly>
                        <NumberInputForm
                          title="Novo valor"
                          name={isTable ? 'onTableSalePrice' : 'salePrice'}
                          max={isTable ? maxOnTableSalePrice : maxSalePrice}
                          onChange={value =>
                            handleChangeSalePrice(value, isTable)
                          }
                        />
                        <FormlessInput
                          title="Porcentagem"
                          name={isTable ? 'onTableSalePercent' : 'salePercent'}
                          type="number"
                          disabled
                          value={
                            isTable ? onTableSalePercentage : salePercentage
                          }
                          onChange={e => handleSalePercentageChange(e, isTable)}
                        />
                      </AuthRole>
                    </div>
                  </Sale>
                ))}
              {selectedProduct && selectedProduct.hash && (
                <FormlessInput
                  readOnly
                  name="hash"
                  title="Link"
                  variant="COPIABLE"
                  value={productLink}
                  onCopy={handleOnLinkCopied}
                />
              )}
              <TextAreaContainer>
                <AuthRole blackList={['Employee']} disableOnly>
                  <TextArea name="description" title="Descrição" />
                </AuthRole>
              </TextAreaContainer>
              <HorizontalWrapper className="margin-top">
                <AuthRole blackList={['Employee']} disableOnly>
                  <Input name="prepareTime" title="Tempo de preparo" />
                </AuthRole>
                <AuthRole blackList={['Employee']} disableOnly>
                  <Input name="sellerSku" title="Código de integração" />
                </AuthRole>
              </HorizontalWrapper>
              {itemSizes && itemSizes.length > 0 && (
                <SizedContainer>
                  <div className="separator-container">
                    <h4>Tamanhos da massa</h4>
                    <div className="separator" />
                  </div>
                  <ProductSizes id="product_sizes">
                    {itemSizes.map(s => (
                      <SizeContainer
                        className="item-size-price-error-container"
                        isActive={s.active}
                      >
                        <SizeWrapper key={`wrapper-${s.pizzaSizeId}`}>
                          <SizeInfo id="size-info">
                            <Span
                              title="Nome"
                              content={s.name}
                              className="size-item"
                            />
                            <Span
                              className="size-item"
                              title="Qtd de sabores"
                              content={String(s.flavours)}
                            />
                            <Span
                              className="size-item"
                              title="Qtd de pedaços"
                              content={String(s.slices)}
                            />
                          </SizeInfo>
                          <SizeInfo>
                            <ItemSizeInput error={s.error}>
                              <span>Preço</span>
                              <AuthRole blackList={['Employee']} disableOnly>
                                <MaskedCurrency
                                  locale="pt-BR"
                                  currency="BRL"
                                  defaultValue={s.price}
                                  onChangeValue={value =>
                                    handleOnChangeSizePrice(
                                      value,
                                      s.pizzaSizeId,
                                    )
                                  }
                                />
                              </AuthRole>
                            </ItemSizeInput>
                          </SizeInfo>
                          <SizeInfo>
                            <ItemSizeInput>
                              <span>Cód. integração</span>
                              <AuthRole blackList={['Employee']} disableOnly>
                                <input
                                  value={s.integrationCode}
                                  onChange={e =>
                                    handleOnChangeSizeIntegration(
                                      e.target.value,
                                      s.pizzaSizeId,
                                    )
                                  }
                                />
                              </AuthRole>
                            </ItemSizeInput>

                            <AuthRole blackList={['Employee']}>
                              <button
                                type="button"
                                onClick={() => {
                                  return toggleProductSize(s.pizzaSizeId);
                                }}
                              >
                                {s.active ? (
                                  <FiEye size={20} />
                                ) : (
                                  <FiEyeOff size={20} />
                                )}
                              </button>
                            </AuthRole>
                          </SizeInfo>
                        </SizeWrapper>
                        {s.error && (
                          <span className="item-size-price-error">
                            O valor dos tamanhos precisa ser preenchido.
                          </span>
                        )}
                      </SizeContainer>
                    ))}
                  </ProductSizes>
                </SizedContainer>
              )}
              <MealSizeContainer>
                <h4>Tamanho da refeição:</h4>
                <RadioContainer onChange={handleMealSizeChange}>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Radio htmlFor="People0">
                      <input
                        type="radio"
                        id="People0"
                        name="mealSize"
                        value="0"
                        checked={mealSize === '0'}
                      />
                      <BsXCircle className="x-circle" />
                      <span>Não se aplica</span>
                    </Radio>
                  </AuthRole>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Radio htmlFor="People1">
                      <input
                        type="radio"
                        id="People1"
                        name="mealSize"
                        value="1"
                        checked={mealSize === '1'}
                      />
                      <Person />
                      <span>Uma pessoa</span>
                    </Radio>
                  </AuthRole>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Radio htmlFor="People2">
                      <input
                        type="radio"
                        id="People2"
                        name="mealSize"
                        value="2"
                        checked={mealSize === '2'}
                      />
                      <TwoPeople className="two-people" />
                      <span>Duas pessoas</span>
                    </Radio>
                  </AuthRole>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Radio htmlFor="People3">
                      <input
                        type="radio"
                        id="People3"
                        name="mealSize"
                        value="3"
                        checked={mealSize === '3'}
                      />
                      <ThreePeople />
                      <span>Três pessoas</span>
                    </Radio>
                  </AuthRole>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Radio htmlFor="People4">
                      <input
                        type="radio"
                        id="People4"
                        name="mealSize"
                        value="4"
                        checked={mealSize === '4'}
                      />
                      <FourPeople />
                      <span>Quatro pessoas</span>
                    </Radio>
                  </AuthRole>
                </RadioContainer>
                <WeightContainer>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <Input
                      name="weight"
                      title="Peso"
                      type="number"
                      style={{ maxWidth: '100px' }}
                    />
                  </AuthRole>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <CreatableSelect
                      styles={selectStyles}
                      className="weight-select"
                      value={selectedMeasurement}
                      options={mealMeasurementsType}
                      placeholder="Unidade de medida"
                      onChange={handleSelectMeasurement}
                      menuPlacement="top"
                    />
                  </AuthRole>
                </WeightContainer>
              </MealSizeContainer>
              <AuthRole blackList={['Employee']}>
                <ButtonContainer>
                  <Button type="submit">
                    <span>Continuar</span>
                    <FiArrowRight size={24} color={theme.palette.text_white} />
                  </Button>
                </ButtonContainer>
              </AuthRole>
            </Information>
          </ScrollContainer>
          <ScrollContainer
            className="has-custom-scroll-bar-2"
            visible={tab === Tabs.COMPLEMENTS}
          >
            <Complements id="complements" className="has-custom-scroll-bar-2">
              <Title>
                <span>Grupos de complementos</span>
                <AuthRole blackList={['Employee']}>
                  <button type="button" onClick={handleAddNewComplementsGroup}>
                    <FiPlus />
                    <span>Novo</span>
                  </button>
                </AuthRole>
              </Title>
              {itemComplementsGroups.length > 0 ? (
                <StyledDragDropContext
                  onDragEnd={handleOnDragEnd}
                  onDragStart={handleOnDragStart}
                >
                  <StyledDroppable droppableId="complements_list">
                    {(providedDroppable, snapshot) => (
                      <div
                        style={
                          snapshot.isDraggingOver ? { background: '#ccc3' } : {}
                        }
                        {...providedDroppable.droppableProps}
                        ref={providedDroppable.innerRef}
                      >
                        {itemComplementsGroups
                          .filter(cg => !cg?.deleted)
                          .map((complementsGroup, index) => (
                            <StyledDraggable
                              key={
                                complementsGroup.id && complementsGroup.id > 0
                                  ? complementsGroup.id
                                  : complementsGroup.localId
                              }
                              draggableId={
                                complementsGroup.id && complementsGroup.id > 0
                                  ? complementsGroup.id?.toString()
                                  : complementsGroup.localId || '0'
                              }
                              index={index}
                            >
                              {providedDraggable => (
                                <DraggableContainer
                                  ref={providedDraggable.innerRef}
                                  {...providedDraggable.draggableProps}
                                  {...providedDraggable.dragHandleProps}
                                  role="button"
                                  tabIndex={-1}
                                >
                                  <ComplementsGroup
                                    complementsGroup={complementsGroup}
                                    onDeleteClick={
                                      handleOnDeleteComplementsGroup
                                    }
                                    beingDragged={
                                      snapshot.isDraggingOver &&
                                      groupBeingDraggedId ===
                                        getGroupId(complementsGroup)
                                    }
                                  />
                                </DraggableContainer>
                              )}
                            </StyledDraggable>
                          ))}
                        {providedDroppable.placeholder}
                      </div>
                    )}
                  </StyledDroppable>
                </StyledDragDropContext>
              ) : (
                <NoComplementsGroupsMessage>
                  <span>
                    Adicione grupos de complementos clicando no botão acima.
                  </span>
                </NoComplementsGroupsMessage>
              )}
            </Complements>
            <AuthRole blackList={['Employee']}>
              <ButtonContainer>
                <Button onClick={handleOnNavigateToSuggested}>
                  {loading ? (
                    <LoadingContainer>
                      <Lottie
                        options={{
                          animationData: animation,
                        }}
                        width={80}
                        height={80}
                        style={{ overflow: 'visible' }}
                      />
                    </LoadingContainer>
                  ) : (
                    <>
                      <span>Continuar</span>
                      <FiArrowRight
                        size={24}
                        color={theme.palette.text_white}
                      />
                    </>
                  )}
                </Button>
              </ButtonContainer>
            </AuthRole>
          </ScrollContainer>

          <ScrollContainer
            className="has-custom-scroll-bar-2"
            visible={tab === Tabs.SUGGESTIONS}
          >
            <SuggestionsModal
              suggestions={suggestions}
              onChange={handleSuggestionChange}
              editingProductId={selectedProduct?.id || 0}
            />
            <AuthRole blackList={['Employee']}>
              <ButtonContainer>
                <Button onClick={handleOnNavigateToAvailability}>
                  <span>Continuar</span>
                  <FiArrowRight size={24} color={theme.palette.text_white} />
                </Button>
              </ButtonContainer>
            </AuthRole>
          </ScrollContainer>
          <ScrollContainer
            className="has-custom-scroll-bar-2"
            visible={tab === Tabs.AVAILABILITY}
          >
            <DispositionContainer>
              <AuthRole blackList={['Employee']} disableOnly>
                <Disposition
                  dispositionTypes={dispositionTypes}
                  onDispositionClicked={handleOnDispositionClicked}
                />
              </AuthRole>
            </DispositionContainer>
            <CheckboxContainer>
              <AuthRole blackList={['Employee']} disableOnly>
                <CustomCheckbox
                  id="always-available"
                  text="Sempre disponível"
                  checked={alwaysAvailable}
                  onChange={handleOnAlwaysAvailableClicked}
                />
              </AuthRole>
              <AuthRole blackList={['Employee']} disableOnly>
                <CustomCheckbox
                  id="not-always-available"
                  checked={notAlwaysAvailable}
                  text="Apenas em dias e horários específicos"
                  onChange={handleOnNotAlwaysAvailableClicked}
                />
              </AuthRole>
            </CheckboxContainer>
            <AvailabilityContainer>
              <AuthRole blackList={['Employee']} disableOnly>
                <Availability
                  interval={{
                    finalTime: intervalFinalTime,
                    initialTime: intervalInitialTime,
                  }}
                  active={notAlwaysAvailable}
                  selectedDays={selectedDays}
                  finalTimeError={finalTimeError}
                  onDayClicked={handleOnDayClicked}
                  initialTimeError={initialTimeError}
                  onChangeInterval={handleOnIntervalChanged}
                />
              </AuthRole>
            </AvailabilityContainer>
            <AuthRole blackList={['Employee']}>
              <ButtonContainer>
                <Button onClick={handleSaveProduct}>
                  {loading ? (
                    <LoadingContainer>
                      <Lottie
                        options={{
                          animationData: animation,
                        }}
                        width={80}
                        height={80}
                        style={{ overflow: 'visible' }}
                      />
                    </LoadingContainer>
                  ) : (
                    <>
                      <span>Salvar</span>
                      <FiCheck size={24} color={theme.palette.text_white} />
                    </>
                  )}
                </Button>
              </ButtonContainer>
            </AuthRole>
          </ScrollContainer>
          <ScrollContainer
            className="has-custom-scroll-bar-2"
            visible={tab === Tabs.COMBO_ITEMS}
          >
            <ComboItemsModal
              comboItems={comboItems}
              onChange={handleComboItemsChange}
              editingProductId={selectedProduct?.id || 0}
            />
            <Button
              type="submit"
              onClick={() => handleOnNavigateToComplements()}
            >
              <span>Continuar</span>
              <FiArrowRight size={24} color={theme.palette.text_white} />
            </Button>
          </ScrollContainer>
          <EditComplementsGroupModal
            onClose={handleClose}
            ref={editComplementsGroupRef}
            onSave={handleOnSaveComplementsGroup}
            visible={isEditComplementsGroupModalOpen}
            complementsGroup={selectedComplementsGroup}
          />
          <OptionsDialog
            title="Escolha"
            options={dialogOptions}
            isOpen={isOptionsDialogOpen}
            onClose={handleOnOptionsDialogClosed}
            onConfirm={handleOnOptionsDialogConfirmed}
          />
          <SearchComplementsModal
            groups={groupsNotAdded}
            isOpen={isSearchDialogOpen}
            onClose={handleOnSearchModalClosed}
            onConfirm={handleOnSearchModalConfirmed}
          />
        </Main>
      </Content>
    </Container>
  );
};

export default EditProductPage;
